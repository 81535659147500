@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
.welcome-view-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: black;
}

.logo-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 20px;
}

.logo {
  width: 300px;
  height: 300px;
}

.app-title {
  color: white;
  font-size: 36px;
  margin-top: 20px;
  font-family: 'Ubuntu', sans-serif;
}

.highlight-text {
  font-weight: bold;
  color: #E39400;
}

.input-container {
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 350px;
}

.login-input {
  width: 300px;
  height: 100%;
  padding-left: 20px;
  border-radius: 10px;
  outline: 0;
  background-color: #141414;
  border: 1px solid #111111;
  color: #bbbbbb;
  font-size: 16px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-family: 'Ubuntu', sans-serif;
}

.login-button {
  transition: 0.5s;
  height: 100%;
  width: 50px;
  background-color: #E39400;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: 0;
  -webkit-appearance: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
}

.login-button:hover {
  cursor: pointer;
  font-size: 25px;
}
.login-button:active {
  outline: 0;
}
.login-button:focus {
  outline: 0;
}

.full-tablet {
  background: url(/static/media/TabletFull.475ad1ab.png);
  background-position: center;
  background-repeat: no-repeat;
  height: 815px;
  width: 615px;
  position: fixed;
  z-index: 10;
}
.background-welcome {
  z-index: -5;
  object-fit: contain;
  height: 100%;
  width: 100%;
  position: absolute;
}

.overlay {
  position: fixed;
  width: 1300px;
  height: 900px;
  -webkit-filter: blur(2px);
          filter: blur(2px);
  pointer-events: none;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.full-tablet-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 86px 23px 0px 23px;
  background: #222222;
  height: 669px;
}

.join-button {
  padding: 10px;
  width: 175px;
  background: rgb(167, 100, 0);
  color: white;
  cursor: pointer;
  font-family: 'Minecraft', cursive;
  text-align: center;
  border: 0;
}

.join-button-selected {
  background: rgb(255, 153, 0);
}


.action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 39px;
  width: 100%;
  margin-bottom: 20px;
}

.join-container {
  z-index: 20;
  height: 250px;
  width: 350px;
  border: 1px solid #AAAAAA;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-input {
  border: 2px solid rgb(255, 153, 0);
  font-family: 'Minecraft', cursive;
  padding: 5px;
  width: 200px;
  background: transparent;
  margin-bottom: 20px;
  color: white;
}

.welcome-input::-webkit-input-placeholder {
  color: #AAAAAA;
}

.welcome-input:-ms-input-placeholder {
  color: #AAAAAA;
}

.welcome-input::placeholder {
  color: #AAAAAA;
}

.join-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 211px;
}
.match-container {
  width: 1300px;
  height: 899px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 0px 100vw #111111;
}

.main-background {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
  z-index: -10;
}

.match {
  width: 100%;
  height: 96%;
}

.match-details {
  width: 20%;
  height: 100%;
  background-color: #AAAAAA;
}

.front {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
  z-index: 0;
}

.kalli {
  position: fixed;
  height: 900px;
  width: 1300px;
  z-index: -1;
  object-fit: contain;
}

.game-board-background {
  position: fixed;
  z-index: -11;
  object-fit: contain;
  height: 900px;
  width: 1300px;
}
.bet-button {
  position: fixed;
  bottom: 38.3%;
  left: 43.7%;
  border-radius: 50%;
  height: 4.1%;
  width: 2%;
  border: 0;
  transition: 0.5s;
  background: transparent;
  cursor: pointer;
}

.draw-button {
  position: fixed;
  bottom: 29%;
  left: 43.7%;
  border-radius: 50%;
  height: 4.1%;
  width: 2%;
  border: 0;
  transition: 0.5s;
  background: transparent;
  cursor: pointer;
}


.give-sip-button {
  position: fixed;
  bottom: 1%;
  left: 3%;
  height: 5%;
  width: 8%;
  border: 0;
  background-color: orange;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: 10px;
}

.give-sip-button:hover {
  cursor: pointer;
}

.bet-light {
  position: fixed;
  object-fit: contain;
  height: 900px;
  width: 1300px;
  transition: 0.5s;
}

.lighting {
  position:fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
}


.white-noise {
  position: fixed;
  opacity: 0.05;
  height: 900px;
  width: 1300px;
  z-index: -11;
}

.cat {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
}

.critter {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
  -webkit-animation: fade 10s infinite alternate;
          animation: fade 10s infinite alternate;
}
.shadows {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button-glow {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
}

.exit {
  position: fixed;
  height: 900px;
  width: 1300px;
}

.exit-on {
  position: absolute;
  height: 900px;
  width: 1300px;
}

.leave-button {
  position: fixed;
  background: transparent;
  outline: 0;
  border: 0;
  height: 55px;
  width: 108px;
  margin: 127px 0px 0px 1162px;
  z-index: 10;
}

.leave-button:hover {
  cursor: pointer;
}
.player-list {
  height: 100%;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.player {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  padding: 10px 10px 10px 15px;
}
.match-player {
  color: #CCCCCC;
  font-size: 20px;
}
.disconnected {
  text-decoration: line-through;
}

.user-avatar {
  height: 100%;
}
.player-details {
  display: flex;
  flex-direction: row;
}
.small-card {
  height: 100%;
}

.bet {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #CCCCCC
}

.player-type {
  color: #999999;
  font-size: 12px;
}

.player-names {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}
.winner-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-family: 'Minecraft', cursive;
}

.sip-button {
  background: transparent;
  color: white;
  font-family: 'Minecraft', cursive;
  border: 0;
}

.player-to-give {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.sip-button:hover {
cursor: pointer;
}
.lost-screen {
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  font-family: 'Minecraft', cursive;
}

.modal-ace {
  height: 70px;
  transition: 0.5s;
}

.modal-ace:hover {
  cursor: pointer;

}

.ace-selected {
  box-shadow: 0px 0px 10px 2px #FFA13D;
  -webkit-box-shadow: 0px 0px 10px 2px #FFA13D;
  -moz-box-shadow: 0px 0px 10px 2px #FFA13D;
}

.glowing {
  box-shadow: 0px 0px 10px 2px #FFA13D;
  -webkit-box-shadow: 0px 0px 10px 2px #FFA13D;
  -moz-box-shadow: 0px 0px 10px 2px #FFA13D;
}

.confirm-bet-button {
  font-family: 'Minecraft', cursive;
  outline: 0;
  border: 0;
  height: 30px;
  width: 150px;
  border-radius: 10px;
  color: white;
  background: rgb(0, 200, 0);
  cursor: pointer;
  box-shadow: 0px 0px 10px 1px rgb(0, 200, 0);
}

.confirm-bet-button:disabled {
  cursor: default;
  box-shadow: 0px 0px 0px 0px rgb(0, 200, 0);
  background: rgb(0, 100, 0);
}

.bet-amount-input {
  margin-top: 10px;
}

.tablet-fingerprints {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 50;
  pointer-events: none;
}

.navigation {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
  z-index: -11;
}

.room-code {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 60px;
  width: 250px;
  margin: 73px 0px 0px 595px;
  background-color: black;
  z-index: -11;
}
.room-code:hover {
  cursor: pointer;
}

.room-code-text {
  position: absolute;
  color: red;
  font-weight: bold;
  font-size: 30px;
  width: -webkit-max-content;
  width: max-content;
  -webkit-animation: marquee 10s infinite linear;
          animation: marquee 10s infinite linear;
  z-index: -10;
  font-family: 'Minecraft', cursive;
}

@-webkit-keyframes marquee {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes marquee {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.tooltip {
  display: none;
  transition: 0.5s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #AAAAAA;
  margin-left: 600px;
  margin-top: 25px;
  height: 30px;
  width: 180px;
  border-radius: 10px;
  background-color: rgba(0,0,0,0.4);
}
.tooltip::before {
  content:'';
  display:block;
  width:0;
  height:0;
  position:absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; 
  border-top: 8px solid rgba(0,0,0,0.4);
  top:100%;
  left: 10%;
}

.copy {
  position: fixed;
  height: 60px;
  width: 250px;
  margin: 73px 0px 0px 595px;
  z-index: 30;
}

.copy:hover {
  cursor: pointer;
}
.recent-cards-container {
  position: fixed;
  width: 91px;
  height: 240px;
  margin: 218px 0px 0px 512px;
  scroll-behavior: smooth;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -2;
}

.recent-card {
  height: 50px;
  margin-bottom: 5px;
  margin-top: 5px;
  -webkit-animation: fadein 2s;
          animation: fadein 2s;
}

.recent-card:last-child {
  margin-bottom: 55px;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.game-board {
  position: fixed;
  height: 900px;
  width: 1300px;
  align-items: center;
  z-index: -11;
}

.carpet {
  position: fixed;
  opacity: 0.5;
  height: 50%;
  width: 20%;
  object-fit: cover;
  z-index: -5;
}

.game-grid-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 276px;
  width: 292px;
  margin: 220px 0px 0px 728px;
}
.game-grid {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  height: 16.66%;
  width: 100%;
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 25%;
}


.hurdle-cards {
  width: 22%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin-right: 4%;
}

.hurdle-card {
  position: absolute;
  transition: 1s;
  width: 59.1%;
}

.card-front-rotate {
  -webkit-animation: flip-front 1s ease-in-out;
          animation: flip-front 1s ease-in-out;
}

@-webkit-keyframes flip-front {
  0% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    z-index: 0;
  }
  50% {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
    z-index: 0;
  }
  100% {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
    z-index: 10;
  }
}

@keyframes flip-front {
  0% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    z-index: 0;
  }
  50% {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
    z-index: 0;
  }
  100% {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
    z-index: 10;
  }
}

.card-back-rotate {
  -webkit-animation: flip-back 1s ease-in-out;
          animation: flip-back 1s ease-in-out;
  z-index: 10;
}

@-webkit-keyframes flip-back {
  0% {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
    z-index: 10;
  }
  50% {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
    z-index: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    z-index: 0;
  }
}

@keyframes flip-back {
  0% {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
    z-index: 10;
  }
  50% {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
    z-index: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    z-index: 0;
  }
}

.race-track {
  width: 26%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.card-front {
  width: 60%;
}

.card-back {
  width: 60%;
}
.row-0 {
  bottom: 4%;
}
.row-1 {
  bottom: 24%;
}
.row-2 {
  bottom: 42.5%;
}
.row-3 {
  bottom: 61%;
}
.row-4 {
  bottom: 81%;
}
.row-5 {
  bottom: 103%;
}
.card-ace {
  position: absolute;
  transition: 1s;
  width: 50%;
}

@-webkit-keyframes row1 {
  0% {
    bottom: 0%;
  }
  50% {
    bottom: 0%;
  }
  100% {
    bottom: 16.66%;
  }
}

@keyframes row1 {
  0% {
    bottom: 0%;
  }
  50% {
    bottom: 0%;
  }
  100% {
    bottom: 16.66%;
  }
}

@-webkit-keyframes row2 {
  0% {
    bottom: 16.66%;
  }
  50% {
    bottom: 16.66%;
  }
  100% {
    bottom: 33.33%;
  }
}

@keyframes row2 {
  0% {
    bottom: 16.66%;
  }
  50% {
    bottom: 16.66%;
  }
  100% {
    bottom: 33.33%;
  }
}

@-webkit-keyframes row3 {
  0% {
    bottom: 33.33%;
  }
  50% {
    bottom: 33.33%;
  }
  100% {
    bottom: 50%;
  }
}

@keyframes row3 {
  0% {
    bottom: 33.33%;
  }
  50% {
    bottom: 33.33%;
  }
  100% {
    bottom: 50%;
  }
}

@-webkit-keyframes row4 {
  0% {
    bottom: 50%;
  }
  50% {
    bottom: 50%;
  }
  100% {
    bottom: 66.66%;
  }
}

@keyframes row4 {
  0% {
    bottom: 50%;
  }
  50% {
    bottom: 50%;
  }
  100% {
    bottom: 66.66%;
  }
}

@-webkit-keyframes row5 {
  0% {
    bottom: 66.66%;
  }
  50% {
    bottom: 66.66%;
  }
  100% {
    bottom: 83.32%;
  }
}

@keyframes row5 {
  0% {
    bottom: 66.66%;
  }
  50% {
    bottom: 66.66%;
  }
  100% {
    bottom: 83.32%;
  }
}

.bet-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.tablet {
  position: absolute;
  z-index: 10;
  bottom: 0%;
  width: 505px;
  height: 396px;
  margin-left: 0.4%;
  transition: 0.5s;
  font-family: 'Minecraft', cursive;
}

.tablet-image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: -1;
}

.tablet:hover {
  cursor: pointer;
}

.tablet-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #222222;
  margin: 70px 0px 0px 18px;
  height: 326px;
  width: 469px;
  z-index: 13;
}

.tablet-content:hover {
  cursor: auto;
}

.tablet-down {
  bottom: -35%;
  overflow: hidden;
}

.modal-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.select-sort {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  height: 5%;
  width: 100%;
  font-family: 'Minecraft', cursive;
  color: #999999;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1 1;
  border-bottom: 1px solid #555555;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.close-modal-button {
  position: absolute;
  border: 0;
  background: transparent;
  color: #AAAAAA;
  font-size: 24px;
  top: 5px;
  right:5px;
}

.close-modal-button:hover {
  color: black;
  cursor: pointer;
}

.modal-random-card {
  height: 70px;
}

.tablet-title {
  font-family: 'Minecraft', cursive;
  color: white;
}
.phone {
  position: absolute;
  z-index: 10;
  bottom: 0%;
  width: 305px;
  height: 362px;
  margin-left: 755px;
  transition: 0.5s;
  font-family: "Minecraft", cursive;
}
.phone:hover {
  cursor: pointer;
}
.phone-down {
  bottom: -30%;
}
.messages {
  height: 281px;
  width: 98%;
  padding-left: 2%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.message-input {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-sent {
  font-size: 12px;
  color: #999999;
}
.user {
  color: #bbbbbb;
  font-size: 14px;
}
.message {
  margin-bottom: 0.5em;
}
.message-text {
  color: #999999;
  font-size: 14px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #222222;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #444444;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444444;
}

.chat-input {
  background-color: #555555;
  border-radius: 0px;
  font-size: 14px;
  font-family: "Minecraft", cursive;
  font-weight: bold;
  width: 98%;
  height: 30px;
  padding: 0px 0px 0px 2%;
  color: #aaaaaa;
  border: 0;
}

.chat-input::-webkit-input-placeholder {
  color: #aaaaaa;
}

.chat-input:-ms-input-placeholder {
  color: #aaaaaa;
}

.chat-input::placeholder {
  color: #aaaaaa;
}

.chat-input:focus-visible {
  outline: 0;
  border: 0;
}

.phone-image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: -1;
}

.phone-content {
  background-color: #222222;
  margin: 50px 0px 0px 14px;
  height: 311px;
  width: 278px;
  z-index: 13;
}

.phone-content:hover {
  cursor: auto;
}

.phone-fingerprints {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

@font-face {
  font-family: 'Minecraft';
  src: local('Minecraft'), url(/static/media/MinecraftRegular-Bmg3.82ba0891.otf) format('truetype');
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  overflow: hidden;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
