.lost-screen {
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  font-family: 'Minecraft', cursive;
}

.modal-ace {
  height: 70px;
  transition: 0.5s;
}

.modal-ace:hover {
  cursor: pointer;

}

.ace-selected {
  box-shadow: 0px 0px 10px 2px #FFA13D;
  -webkit-box-shadow: 0px 0px 10px 2px #FFA13D;
  -moz-box-shadow: 0px 0px 10px 2px #FFA13D;
}

.glowing {
  box-shadow: 0px 0px 10px 2px #FFA13D;
  -webkit-box-shadow: 0px 0px 10px 2px #FFA13D;
  -moz-box-shadow: 0px 0px 10px 2px #FFA13D;
}

.confirm-bet-button {
  font-family: 'Minecraft', cursive;
  outline: 0;
  border: 0;
  height: 30px;
  width: 150px;
  border-radius: 10px;
  color: white;
  background: rgb(0, 200, 0);
  cursor: pointer;
  box-shadow: 0px 0px 10px 1px rgb(0, 200, 0);
}

.confirm-bet-button:disabled {
  cursor: default;
  box-shadow: 0px 0px 0px 0px rgb(0, 200, 0);
  background: rgb(0, 100, 0);
}

.bet-amount-input {
  margin-top: 10px;
}

.tablet-fingerprints {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 50;
  pointer-events: none;
}