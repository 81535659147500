.welcome-view-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: black;
}

.logo-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 20px;
}

.logo {
  width: 300px;
  height: 300px;
}

.app-title {
  color: white;
  font-size: 36px;
  margin-top: 20px;
  font-family: 'Ubuntu', sans-serif;
}

.highlight-text {
  font-weight: bold;
  color: #E39400;
}

.input-container {
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 350px;
}

.login-input {
  width: 300px;
  height: 100%;
  padding-left: 20px;
  border-radius: 10px;
  outline: 0;
  background-color: #141414;
  border: 1px solid #111111;
  color: #bbbbbb;
  font-size: 16px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-family: 'Ubuntu', sans-serif;
}

.login-button {
  transition: 0.5s;
  height: 100%;
  width: 50px;
  background-color: #E39400;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: 0;
  -webkit-appearance: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
}

.login-button:hover {
  cursor: pointer;
  font-size: 25px;
}
.login-button:active {
  outline: 0;
}
.login-button:focus {
  outline: 0;
}

.full-tablet {
  background: url('../../resources/TabletFull.png');
  background-position: center;
  background-repeat: no-repeat;
  height: 815px;
  width: 615px;
  position: fixed;
  z-index: 10;
}
.background-welcome {
  z-index: -5;
  object-fit: contain;
  height: 100%;
  width: 100%;
  position: absolute;
}

.overlay {
  position: fixed;
  width: 1300px;
  height: 900px;
  filter: blur(2px);
  pointer-events: none;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.full-tablet-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 86px 23px 0px 23px;
  background: #222222;
  height: 669px;
}

.join-button {
  padding: 10px;
  width: 175px;
  background: rgb(167, 100, 0);
  color: white;
  cursor: pointer;
  font-family: 'Minecraft', cursive;
  text-align: center;
  border: 0;
}

.join-button-selected {
  background: rgb(255, 153, 0);
}


.action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 39px;
  width: 100%;
  margin-bottom: 20px;
}

.join-container {
  z-index: 20;
  height: 250px;
  width: 350px;
  border: 1px solid #AAAAAA;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-input {
  border: 2px solid rgb(255, 153, 0);
  font-family: 'Minecraft', cursive;
  padding: 5px;
  width: 200px;
  background: transparent;
  margin-bottom: 20px;
  color: white;
}

.welcome-input::placeholder {
  color: #AAAAAA;
}

.join-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 211px;
}