.match-container {
  width: 1300px;
  height: 899px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 0px 100vw #111111;
}

.main-background {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
  z-index: -10;
}

.match {
  width: 100%;
  height: 96%;
}

.match-details {
  width: 20%;
  height: 100%;
  background-color: #AAAAAA;
}

.front {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
  z-index: 0;
}

.kalli {
  position: fixed;
  height: 900px;
  width: 1300px;
  z-index: -1;
  object-fit: contain;
}

.game-board-background {
  position: fixed;
  z-index: -11;
  object-fit: contain;
  height: 900px;
  width: 1300px;
}
.bet-button {
  position: fixed;
  bottom: 38.3%;
  left: 43.7%;
  border-radius: 50%;
  height: 4.1%;
  width: 2%;
  border: 0;
  transition: 0.5s;
  background: transparent;
  cursor: pointer;
}

.draw-button {
  position: fixed;
  bottom: 29%;
  left: 43.7%;
  border-radius: 50%;
  height: 4.1%;
  width: 2%;
  border: 0;
  transition: 0.5s;
  background: transparent;
  cursor: pointer;
}


.give-sip-button {
  position: fixed;
  bottom: 1%;
  left: 3%;
  height: 5%;
  width: 8%;
  border: 0;
  background-color: orange;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: 10px;
}

.give-sip-button:hover {
  cursor: pointer;
}

.bet-light {
  position: fixed;
  object-fit: contain;
  height: 900px;
  width: 1300px;
  transition: 0.5s;
}

.lighting {
  position:fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
}


.white-noise {
  position: fixed;
  opacity: 0.05;
  height: 900px;
  width: 1300px;
  z-index: -11;
}

.cat {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
}

.critter {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
  animation: fade 10s infinite alternate;
}
.shadows {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button-glow {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
}

.exit {
  position: fixed;
  height: 900px;
  width: 1300px;
}

.exit-on {
  position: absolute;
  height: 900px;
  width: 1300px;
}

.leave-button {
  position: fixed;
  background: transparent;
  outline: 0;
  border: 0;
  height: 55px;
  width: 108px;
  margin: 127px 0px 0px 1162px;
  z-index: 10;
}

.leave-button:hover {
  cursor: pointer;
}