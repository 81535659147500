.player-list {
  height: 100%;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.player {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  padding: 10px 10px 10px 15px;
}
.match-player {
  color: #CCCCCC;
  font-size: 20px;
}
.disconnected {
  text-decoration: line-through;
}

.user-avatar {
  height: 100%;
}
.player-details {
  display: flex;
  flex-direction: row;
}
.small-card {
  height: 100%;
}

.bet {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #CCCCCC
}

.player-type {
  color: #999999;
  font-size: 12px;
}

.player-names {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}