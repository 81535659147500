.game-board {
  position: fixed;
  height: 900px;
  width: 1300px;
  align-items: center;
  z-index: -11;
}

.carpet {
  position: fixed;
  opacity: 0.5;
  height: 50%;
  width: 20%;
  object-fit: cover;
  z-index: -5;
}

.game-grid-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 276px;
  width: 292px;
  margin: 220px 0px 0px 728px;
}
.game-grid {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  height: 16.66%;
  width: 100%;
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 25%;
}


.hurdle-cards {
  width: 22%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin-right: 4%;
}

.hurdle-card {
  position: absolute;
  transition: 1s;
  width: 59.1%;
}

.card-front-rotate {
  animation: flip-front 1s ease-in-out;
}

@keyframes flip-front {
  0% {
    transform: rotateY(0deg);
    z-index: 0;
  }
  50% {
    transform: rotateY(90deg);
    z-index: 0;
  }
  100% {
    transform: rotateY(90deg);
    z-index: 10;
  }
}

.card-back-rotate {
  animation: flip-back 1s ease-in-out;
  z-index: 10;
}

@keyframes flip-back {
  0% {
    transform: rotateY(90deg);
    z-index: 10;
  }
  50% {
    transform: rotateY(90deg);
    z-index: 0;
  }
  100% {
    transform: rotateY(0deg);
    z-index: 0;
  }
}

.race-track {
  width: 26%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.card-front {
  width: 60%;
}

.card-back {
  width: 60%;
}
.row-0 {
  bottom: 4%;
}
.row-1 {
  bottom: 24%;
}
.row-2 {
  bottom: 42.5%;
}
.row-3 {
  bottom: 61%;
}
.row-4 {
  bottom: 81%;
}
.row-5 {
  bottom: 103%;
}
.card-ace {
  position: absolute;
  transition: 1s;
  width: 50%;
}

@keyframes row1 {
  0% {
    bottom: 0%;
  }
  50% {
    bottom: 0%;
  }
  100% {
    bottom: 16.66%;
  }
}

@keyframes row2 {
  0% {
    bottom: 16.66%;
  }
  50% {
    bottom: 16.66%;
  }
  100% {
    bottom: 33.33%;
  }
}

@keyframes row3 {
  0% {
    bottom: 33.33%;
  }
  50% {
    bottom: 33.33%;
  }
  100% {
    bottom: 50%;
  }
}

@keyframes row4 {
  0% {
    bottom: 50%;
  }
  50% {
    bottom: 50%;
  }
  100% {
    bottom: 66.66%;
  }
}

@keyframes row5 {
  0% {
    bottom: 66.66%;
  }
  50% {
    bottom: 66.66%;
  }
  100% {
    bottom: 83.32%;
  }
}

.bet-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.tablet {
  position: absolute;
  z-index: 10;
  bottom: 0%;
  width: 505px;
  height: 396px;
  margin-left: 0.4%;
  transition: 0.5s;
  font-family: 'Minecraft', cursive;
}

.tablet-image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: -1;
}

.tablet:hover {
  cursor: pointer;
}

.tablet-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #222222;
  margin: 70px 0px 0px 18px;
  height: 326px;
  width: 469px;
  z-index: 13;
}

.tablet-content:hover {
  cursor: auto;
}

.tablet-down {
  bottom: -35%;
  overflow: hidden;
}

.modal-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.select-sort {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  height: 5%;
  width: 100%;
  font-family: 'Minecraft', cursive;
  color: #999999;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #555555;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.close-modal-button {
  position: absolute;
  border: 0;
  background: transparent;
  color: #AAAAAA;
  font-size: 24px;
  top: 5px;
  right:5px;
}

.close-modal-button:hover {
  color: black;
  cursor: pointer;
}

.modal-random-card {
  height: 70px;
}

.tablet-title {
  font-family: 'Minecraft', cursive;
  color: white;
}