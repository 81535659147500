.phone {
  position: absolute;
  z-index: 10;
  bottom: 0%;
  width: 305px;
  height: 362px;
  margin-left: 755px;
  transition: 0.5s;
  font-family: "Minecraft", cursive;
}
.phone:hover {
  cursor: pointer;
}
.phone-down {
  bottom: -30%;
}
.messages {
  height: 281px;
  width: 98%;
  padding-left: 2%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.message-input {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-sent {
  font-size: 12px;
  color: #999999;
}
.user {
  color: #bbbbbb;
  font-size: 14px;
}
.message {
  margin-bottom: 0.5em;
}
.message-text {
  color: #999999;
  font-size: 14px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #222222;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #444444;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444444;
}

.chat-input {
  background-color: #555555;
  border-radius: 0px;
  font-size: 14px;
  font-family: "Minecraft", cursive;
  font-weight: bold;
  width: 98%;
  height: 30px;
  padding: 0px 0px 0px 2%;
  color: #aaaaaa;
  border: 0;
}

.chat-input::placeholder {
  color: #aaaaaa;
}

.chat-input:focus-visible {
  outline: 0;
  border: 0;
}

.phone-image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: -1;
}

.phone-content {
  background-color: #222222;
  margin: 50px 0px 0px 14px;
  height: 311px;
  width: 278px;
  z-index: 13;
}

.phone-content:hover {
  cursor: auto;
}

.phone-fingerprints {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
