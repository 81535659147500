.recent-cards-container {
  position: fixed;
  width: 91px;
  height: 240px;
  margin: 218px 0px 0px 512px;
  scroll-behavior: smooth;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -2;
}

.recent-card {
  height: 50px;
  margin-bottom: 5px;
  margin-top: 5px;
  animation: fadein 2s;
}

.recent-card:last-child {
  margin-bottom: 55px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}