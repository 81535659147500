.winner-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-family: 'Minecraft', cursive;
}

.sip-button {
  background: transparent;
  color: white;
  font-family: 'Minecraft', cursive;
  border: 0;
}

.player-to-give {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.sip-button:hover {
cursor: pointer;
}