
.navigation {
  position: fixed;
  height: 900px;
  width: 1300px;
  object-fit: contain;
  z-index: -11;
}

.room-code {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 60px;
  width: 250px;
  margin: 73px 0px 0px 595px;
  background-color: black;
  z-index: -11;
}
.room-code:hover {
  cursor: pointer;
}

.room-code-text {
  position: absolute;
  color: red;
  font-weight: bold;
  font-size: 30px;
  width: max-content;
  animation: marquee 10s infinite linear;
  z-index: -10;
  font-family: 'Minecraft', cursive;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.tooltip {
  display: none;
  transition: 0.5s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #AAAAAA;
  margin-left: 600px;
  margin-top: 25px;
  height: 30px;
  width: 180px;
  border-radius: 10px;
  background-color: rgba(0,0,0,0.4);
}
.tooltip::before {
  content:'';
  display:block;
  width:0;
  height:0;
  position:absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; 
  border-top: 8px solid rgba(0,0,0,0.4);
  top:100%;
  left: 10%;
}

.copy {
  position: fixed;
  height: 60px;
  width: 250px;
  margin: 73px 0px 0px 595px;
  z-index: 30;
}

.copy:hover {
  cursor: pointer;
}