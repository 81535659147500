@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

@font-face {
  font-family: 'Minecraft';
  src: local('Minecraft'), url(../fonts/MinecraftRegular-Bmg3.otf) format('truetype');
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  overflow: hidden;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}